<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="100vw"
      min-width="100vw"
      class="pa-20 ma-20"
      transition="dialog-top-transition"
      @click:outside="closeDetailsContactModal()"
    >
      <div>xxxxxxx</div>
      <div v-if="contactToShowDetails">
        <v-card width="100vw" height="90vh" class="ma-0 pa-0">
          <v-toolbar flat color="transparent">
            <v-toolbar-title class="headline">
              Información del Asesor
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn light icon text @click="closeDetailsContactModal()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row align="center">
              <v-col
                class="ma-0 pa-0"
                cols="12"
                sm="3"
                justify="end"
                align="center"
              >
                <v-card
                  class="pa-5 grey lighten-3"
                  width="100%"
                  height="70vh"
                  elevation="0"
                >
                  <v-container fill-height>
                    <v-row justify="center" align="center">
                      <v-col cols="12" justify="center" align="center">
                        <v-avatar color="indigo" width="20vh" height="20vh">
                          <img
                            :src="getProfileImg(contactToShowDetails.media)"
                            :alt="contactToShowDetails.name"
                          />
                        </v-avatar>
                      </v-col>

                      <v-col cols="12" sm="12" align="start" justify="start">
                        <div>
                          <h4>
                            Fecha de registro:
                          </h4>
                        </div>
                        <v-row align="start" justify="start">
                          <v-col cols="12" class="pa-5">
                            <div>
                              {{
                                getSpanishDate(contactToShowDetails.created_at)
                              }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" sm="12" align="start" justify="start">
                        <div>
                          <h4>
                            Ultima Actualización:
                          </h4>
                        </div>
                        <v-row align="start" justify="start">
                          <v-col cols="12" class="pa-5">
                            <div>
                              {{
                                getSpanishDate(contactToShowDetails.updated_at)
                              }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6">
                <v-card
                  class="pa-10 grey lighten-3"
                  width="100%"
                  height="70vh"
                  elevation="0"
                >
                  <v-container fill-height>
                    <v-row justify="center" align="center">
                      <v-col cols="6">
                        <div>
                          <h4>Nombre</h4>
                        </div>
                        <div v-if="contactToShowDetails.name">
                          {{ contactToShowDetails.name }}
                        </div>
                        <div v-else>
                          --
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <h4>Role</h4>
                        </div>
                        <div v-if="contactToShowDetails.role">
                          {{
                            contactToShowDetails.role === "admin"
                              ? "Administrador"
                              : "Broker"
                          }}
                        </div>
                        <div v-else>
                          --
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <div>
                          <h4>E-Mail</h4>
                        </div>
                        <div v-if="contactToShowDetails.email">
                          {{ contactToShowDetails.email }}
                        </div>
                        <div v-else>
                          --
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <h4>Teléfono</h4>
                        </div>
                        <div v-if="contactToShowDetails.phone">
                          {{ contactToShowDetails.phone }}
                        </div>
                        <div v-else>
                          --
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <div>
                          <h4>
                            Proyecto asignados
                          </h4>
                        </div>
                        <v-row align="center" justify="start">
                          <v-col
                            v-if="selections.length === 0"
                            cols="12"
                            class="pa-5"
                          >
                            No has asignado ningun proyecto para este asesor
                          </v-col>
                          <v-col
                            v-else
                            v-for="(selection, i) in selections"
                            :key="i"
                            cols="5"
                            class="shrink"
                          >
                            <v-chip>
                              {{ selection.name }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" sm="3">
                <v-card
                  class="py-10 px-2 grey lighten-3"
                  width="100%"
                  height="70vh"
                  elevation="0"
                >
                  <v-container fill-height>
                    <v-row justify="center" align="center">
                      <v-col cols="12">
                        <div>
                          <h4>Performance</h4>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <v-progress-circular
                            :rotate="360"
                            :size="150"
                            :width="10"
                            :value="
                              getPerformance(contactToShowDetails.performance)
                            "
                            color="primary"
                          >
                            {{
                              getPerformance(contactToShowDetails.performance)
                            }}%
                          </v-progress-circular>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- <v-card-actions>
            <v-btn text>
              Full Report
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </div>
      <div v-else>
        Detalles
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
export default {
  props: {
    realEstate: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      contactToShowDetails: null,
      search: "",
      selectedRealEstate: []
    };
  },
  computed: {
    ...mapState({
      contactListSt: state => state.contacts.contactList
    }),
    allSelected() {
      return this.selectedRealEstate.length === this.realEstate.length;
    },
    availableRealEstate() {
      if (!this.search.toLowerCase()) {
        return this.realEstate;
      } else {
        return this.realEstate.filter(item => {
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },
    selections() {
      const selections = [];
      for (const selection of this.selectedRealEstate) {
        selections.push(selection);
      }
      return selections;
    }
  },
  watch: {
    selectedRealEstate() {
      this.search = "";
    }
  },
  methods: {
    ...mapActions({
      createUserStore: "contacts/createUser"
    }),
    setContactToShowDetails(value) {
      this.selectedRealEstate = [];
      this.contactToShowDetails = value;
      for (let i = 0; i < value.real_estate_develop_id.length; i++) {
        const list = this.realEstate.find(l => {
          return l._id === value.real_estate_develop_id[i];
        });
        if (list) {
          this.selectedRealEstate.push(list);
        }
      }
      this.show = true;
    },
    getPerformance(values) {
      let performance =
        values.total === 0 ? 0 : (values.active * 100) / values.total;
      return Math.round(performance);
    },
    getSpanishDate(date) {
      if (date === undefined || date === null || date === "") {
        return moment(new Date())
          .locale("es")
          .format("LL");
      } else {
        return moment(new Date(date))
          .locale("es")
          .format("LL");
      }
    },
    closeDetailsContactModal() {
      this.show = false;
      this.$emit("close", this.updatedData);
    },
    getProfileImg(media) {
      if (media && media.hasOwnProperty("featured_image")) {
        if (media.featured_image.hasOwnProperty("src")) {
          if (
            media.featured_image.src !== null &&
            media.featured_image.src !== ""
          ) {
            return media.featured_image.src;
          } else {
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
          }
        } else if (media.featured_image.hasOwnProperty("src_avatar")) {
          if (
            media.featured_image.src_avatar !== null &&
            media.featured_image.src_avatar !== ""
          ) {
            return media.featured_image.src_avatar;
          } else {
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
          }
        } else {
          return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
        }
      } else {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/OOjs_UI_icon_userAvatar-progressive.svg/600px-OOjs_UI_icon_userAvatar-progressive.svg.png";
      }
    }
  }
};
</script>

<style scoped>
.v-dialog {
  border-radius: 24px !important;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
.v-expansion-panel {
  box-shadow: none;
}
.password {
  font-weight: 500;
  font-size: 12px;
}
</style>
